import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultTemplate from "../templates/defaultTemplate.js";
export const trackProps = {
  title: `Customer Data`,
  type: `Template`,
  consumption_method: 'Download'
};
export const _frontmatter = {
  "title": "Customer Data Template Download",
  "slug": "tp-download",
  "resource_download_url": "https://docs.google.com/spreadsheets/d/1BA_MyTnAUWv7a66tuWzoZOSmWHqjJZHNtzDMM9aEpWg",
  "resource_name": "Customer Data Template",
  "my_email": "m@michaelsheedy.com",
  "my_email_link": "mailto:m@michaelsheedy.com?subject=Customer Data Template&body=Hi Michael,%0D%0A%0D%0A",
  "url_to_this_page": "https://michaelsheedy.com/customer-data-template",
  "email_url_this_page": "mailto:?subject=Checkout this great template&body=Hey,%0D%0A%0D%0AI Found this Customer Data Template and thought you might like it.%0D%0A%0D%0A"
};
const layoutProps = {
  trackProps,
  _frontmatter
};
const MDXLayout = DefaultTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Thank You`}</h1>
    <a href={props.pageContext.frontmatter.resource_download_url}>Download the {props.pageContext.frontmatter.resource_name}</a>
    <hr></hr>
    <p>
  <em>Do you know someone who might benefit from this {props.pageContext.frontmatter.resource_name}? You can share it with them by clicking here: <a href={props.pageContext.frontmatter.email_url_this_page + "\n" + props.pageContext.frontmatter.url_to_this_page}>Share {props.pageContext.frontmatter.resource_name}</a></em>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      